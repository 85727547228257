import ConstCode from '@/common/constants/ConstCode';

export const telTo = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const device = isMobile ? 'mobile' : 'pc';

  if (device === 'pc') alert('PC에서는 전화를 연결할 수 없습니다. \n1544-6821로 전화해주세요');
  else location.href = 'tel:1544-6821';
};

export const goKakaoChannel = () => {
  window.open(ConstCode.KAKAO_CHANNEL, '_blank');
};

export const naverMap = () => {
  window.open(ConstCode.NAVER_MAP, '_blank');
};
export const kakaoMap = () => {
  window.open(ConstCode.KAKAO_MAP, '_blank');
};
